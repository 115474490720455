$(document).ready(function() {


    function setAlgContainerHeight() {
        if ($('#js-alg-body-wrap').length) {
             var viewPortWidth;
             var viewPortHeight;

             // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
             if (typeof window.innerWidth != 'undefined') {
               viewPortWidth = window.innerWidth,
               viewPortHeight = window.innerHeight
             }

            $('#js-alg-body-wrap').removeAttr("style");
            var headerHeight = $('.header').height();
            // var headerHeight = 52;
            var footerHeight = $('.footer').height();
            // var footerHeight = 245;
            var algBodyHeight = $('#js-alg-body-wrap').height();

            var docHeight = headerHeight + algBodyHeight + footerHeight;
            var htmlHeight = $('html').height();

            // console.log('viewPortHeight: ' + viewPortHeight);
            // console.log('HTML height: ' + htmlHeight);
            // console.log('docHeight Height: ' + docHeight);
            // console.log('header height: ' + headerHeight);
            // console.log('footer height: ' + footerHeight);
            // console.log('algBody height: ' + algBodyHeight);

            if (docHeight < viewPortHeight) {
                var newHeight = viewPortHeight - headerHeight - footerHeight;
                // console.log('New Height: ' + newHeight);
                if (viewPortWidth > 1400) {
                    newHeight = newHeight - 10;
                }
                $('#js-alg-body-wrap').height(newHeight - 10);
            }
            else {
                // console.log('something really wrong');
            }

        } else {
            // console.log('something went wrong');
        }
    }


    function RemoveInstallAppNavLink() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            if(document.getElementById("js-install-prompt") == null) {
                console.log("Links Already Removed")
            } else {
                console.log("App Installed => Removing Install Links")
                // var elem = document.querySelector('#js-install-prompt');
                // elem.parentNode.removeChild(elem);
                $('#js-install-prompt').remove();
            }
        }
    }


    function breakMainNav() {
        if ($('.js-nav-sub').is(':visible')) {
            $('.js-nav-sub').hide();
        }
        if ($('#js-nav-main').hasClass('is-active')) {
            $('#js-nav-main').removeClass('is-active');
        }
        if ($('#js-nav-main').is(':visible')) {
            $('#js-nav-main').hide();
            $('#js-nav-main').removeClass('is-active');
            $('#js-nav-toggle').children('i').toggleClass('fa-times');
            $('#js-nav-toggle').children('i').toggleClass('fa-bars');
        }
        if ($('#js-overlay').hasClass('is-active')) {
            $('#js-overlay').hide();
            $('#js-overlay').height( 52 );
            $('#js-overlay').toggleClass('is-active');
        }
    }

    function breakFilterNav() {
        if ($('#js-nav-filter').length) {
            if ($('#js-nav-filter').hasClass('is-active')) {
                $('#js-nav-filter').removeClass('is-active');
                $('#js-filter-toggle').children('i').toggleClass('fa-filter');
                // $('#js-filter-toggle').children('i').toggleClass('fab');
                $('#js-filter-toggle').children('i').toggleClass('fa-times');
                // $('#js-filter-toggle').children('i').toggleClass('fas');
            }
            if ($('#js-overlay-filter').hasClass('is-active')) {
                $('#js-overlay-filter').hide();
                $('#js-overlay-filter').height( 52 );
                $('#js-overlay-filter').toggleClass('is-active');
            }
        }
    }

    function breakAllNav() {
        breakMainNav();
        breakFilterNav();
    }

    // Prevent offsite links when offline
    $('a').click( function(event) {
        if (navigator.onLine) {
            // Do Nothing
        } else {
            if ($(this).attr("href").includes("http")) {
                event.preventDefault();
                alert("OOOPS! \nTHIS IS NOT AN ERROR. \nThis action requires a connection to the internet.");
            }
        }
    });

    // Prevent offsite links when offline
    $('form').click( function(event) {
        if (navigator.onLine) {
            // Do Nothing
        } else {
            event.preventDefault();
            alert("OOOPS! \nTHIS IS NOT AN ERROR. \nThis action requires a connection to the internet.");
        }
    });



    if ($('#js-nav-filter li').length) {
        $('#js-nav-filter li').click( function(){
            if ($(this).hasClass('is-inactive')) {
                $('#js-nav-filter li').each(function(){
                    if($(this).hasClass('is-active')) {
                        $(this).removeClass('is-active');
                        $(this).addClass('is-inactive');
                    }
                })
                $(this).removeClass('is-inactive');
                $(this).addClass('is-active');

                // TODO Add filter logic here
                var filterAtt = $(this).attr('data-filter');
                var filterSections = $('.js-alg-filter');

                if (filterSections.hasClass('js-active-filter')) {
                    filterSections.removeClass('js-active-filter');
                }
                filterSections.show();

                if (filterAtt !== 'all') {
                    filterSections.each( function() {
                        var algAttr = $(this).attr('data-filter');
                        if ( algAttr !== filterAtt) {
                            $(this).hide();
                        } else {
                            if (!filterSections.hasClass('js-active-filter')) {
                                filterSections.addClass('js-active-filter');
                            }
                        }
                    });
                }

                setAlgContainerHeight();

                $('#js-filter-label').html( $(this).text());
                breakFilterNav();
            }
            breakMainNav();
        });
    }


    // Filter Menu Toggle
    if ($('#js-filter-toggle').length) {
        $('#js-filter-toggle').click(function(){
            if ($(this).next().next('#js-nav-filter').hasClass('is-active')) {
                $(this).next().next('#js-nav-filter').removeClass('is-active');
                $(this).children('i').toggleClass('fa-filter');
                // $(this).children('i').toggleClass('fab');
                $(this).children('i').toggleClass('fa-times');
                // $(this).children('i').toggleClass('fas');
            } else {
                $(this).next().next('#js-nav-filter').addClass('is-active');
                $(this).children('i').toggleClass('fa-filter');
                // $(this).children('i').toggleClass('fab');
                $(this).children('i').toggleClass('fa-times');
                // $(this).children('i').toggleClass('fas');
            }
            if ($(this).next().next('#js-nav-filter').next('#js-overlay-filter').is(':visible')) {
                $(this).next().next('#js-nav-filter').next('#js-overlay-filter').hide();
                $('#js-overlay-filter').height( 52 );
            } else {
                var containerHeight = $( 'body' ).height();
                $(this).next().next('#js-nav-filter').next('#js-overlay-filter').height( containerHeight );
                $(this).next().next('#js-nav-filter').next('#js-overlay-filter').show();
            }
            $(this).next().next('#js-nav-filter').next('#js-overlay-filter').toggleClass('is-active');
            breakMainNav();
        });
    }


    function doOnOrientationChange() {
        breakAllNav();
        setAlgContainerHeight();
    }
    window.addEventListener('orientationchange', doOnOrientationChange);


    window.addEventListener("resize", resizeThrottler, false);

    var resizeTimeout;
    function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if ( !resizeTimeout ) {
        resizeTimeout = setTimeout(function() {
            resizeTimeout = null;
            actualResizeHandler();

       // The actualResizeHandler will execute at a rate of 15fps
       }, 66);
    }
    }


    function actualResizeHandler() {
        if (window.innerWidth > 1023) {
            breakAllNav();
        }
        setAlgContainerHeight();
    }








    // Dropdown toggle
    $('#js-nav-toggle').click(function(){

        if ($(this).next('#js-nav-main').is(':visible')) {
            $(this).next('#js-nav-main').hide();
            $('.js-nav-sub').hide();
            $(this).children('i').toggleClass('fa-times');
            $(this).children('i').toggleClass('fa-bars');
            if ($('#js-nav-main').hasClass('is-active')) {
                $('#js-nav-main').removeClass('is-active');
            }
        } else {
            $(this).next('#js-nav-main').show();
            $(this).children('i').toggleClass('fa-times');
            $(this).children('i').toggleClass('fa-bars');
            $(this).next('#js-nav-main').addClass('is-active');
            if (!$('#js-nav-main').hasClass('is-active')) {
                $('#js-nav-main').addClass('is-active');
            }
        }
        if ($(this).next('#js-nav-main').next('#js-overlay').is(':visible')) {
            $(this).next('#js-nav-main').next('#js-overlay').hide();
            $('#js-overlay').height( 52 );
        } else {
            var containerHeight = $( 'html' ).height();
            $(this).next('#js-nav-main').next('#js-overlay').height( containerHeight );
            $(this).next('#js-nav-main').next('#js-overlay').show();
        }
        RemoveInstallAppNavLink();
        $(this).next('#js-nav-main').next('#js-overlay').toggleClass('is-active');
        breakFilterNav();
    });


    $('.js-nav-sub-toggle').click(function(){
        $(this).next('.js-nav-sub').slideToggle(400, function() {
            $('#js-overlay').height( $( 'html' ).height() );
        });
        $(this).toggleClass('is-active');
    });

    $('#js-overlay, #js-overlay-filter').click(function(){
        breakAllNav();
    });

    $(document).click(function(e) {
        var target = e.target;

        if ($(target).is('.js-nav-sub')) {
            if ($('.js-nav-sub').is(':visible')) {
                $('.js-nav-sub').hide();
            }
        }
    });


    var currentYear = (new Date).getFullYear();
    var iniYear = $('#iniYear').text();
    $(function copy() {
        if (iniYear != currentYear) {
            $("#currentYear").removeClass('is-hidden');
            $("#currentYear").text( '-' + currentYear );
        }

    });



    // if(window.navigator.standalone) {
    //     $("meta[name='apple-mobile-web-app-status-bar-style']").remove();
    //     $('html').addClass('webapp');
    // }


});



